<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <AMonthPicker
                    class="mr-2 mt-2"
                    v-model:value="state.params.month"
                    placeholder="Pilih Bulan"
                    :disabled-date="disabledDate"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterBrand
                    class="mr-2"
                    v-model:value="state.params.brand"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
                <FilterKabupatenKota
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"/>
                <FilterToko
                    class="mt-2 mr-2"
                    v-model:value="state.params.toko"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-7 col-md-12 mt-auto">
                <div class="card">
                    <div class="card-body">
                        <GraphBarChart
                            :data="state.graph"
                            :loading="state.loadingGraph"
                            title="Diagram Toko Aktif"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 text-right mt-auto">
                <div class="card">
                    <div class="card-body">
                        <AreaBarChart
                            :data="state.area"
                            :loading="state.loadingArea"
                            title="Toko Area Aktif"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
                <strong>{{ state.openClosePriodeLastTime }}</strong>
                <ASkeleton
                    :paragraph="{ rows: 0 }"
                    :loading="state.syncFinish.loading">
                    <AProgress
                        :percent="state.syncFinish.total"
                        status="active"/>
                </ASkeleton>
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <ASpace>
                    <ATooltip title="Syncron">
                        <AButton
                            :loading="state.isSync"
                            type="primary"
                            :disabled="!state.openClosePriode"
                            @click="btnSynchron()">
                            <i class="fa fa-refresh"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download Excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="state.params"
                            namefile="Laporan-Toko-Aktif-(CA)"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterToko from '@/components/filter/FilterToko'
import AreaBarChart from './AreaBarChart'
import GraphBarChart from './GraphBarChart'
import { debounce, pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        DownloadExcel,
        FilterKabupatenKota,
        FilterDistributor,
        FilterRegional,
        FilterArea,
        FilterBrand,
        FilterToko,
        AreaBarChart,
        GraphBarChart,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'kode_toko',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'nama_toko',
                },
                {
                    title: 'Alamat Toko',
                    dataIndex: 'alamat_toko',
                },
                {
                    title: 'Kabupaten Toko',
                    dataIndex: 'kabupaten_toko',
                },
                {
                    title: 'Provinsi Toko',
                    dataIndex: 'provinsi_toko',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'region_toko',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'area_toko',
                },

                {
                    title: 'Brands',
                    dataIndex: 'brands',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'kode_produk',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'nama_produk',
                },
                {
                    title: 'KD MD Royalti',
                    dataIndex: 'kode_md_royalty',
                },
                {
                    title: 'NM MD Royalti',
                    dataIndex: 'nama_md_royalty',
                },
                {
                    title: 'Harga',
                    dataIndex: 'harga',
                },
                {
                    title: 'QTY Zak',
                    dataIndex: 'qty_zak',
                },
                {
                    title: 'QTY Ton',
                    dataIndex: 'qty_ton',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'no_transaksi',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'kode_gudang',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'nama_gudang',
                },
                {
                    title: 'Regional Gudang',
                    dataIndex: 'region_gudang',
                },
                {
                    title: 'Provinsi Gudang',
                    dataIndex: 'provinsi_gudang',
                },
                {
                    title: 'Area Gudang',
                    dataIndex: 'area_gudang',
                },
                {
                    title: 'Kabupaten Gudang',
                    dataIndex: 'kabupaten_gudang',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Cluster',
                    dataIndex: 'cluster',
                },

                {
                    title: 'SSM',
                    dataIndex: 'ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'am',
                },
            ],
            endpoint: {
                current: '/api/report/transaksi-ca-v2',
                sync: '/api/synchrons/transaction-ca-v2',
                sync_total: '/api/synchrons/transaction-ca-v2/total-finished',
            },
            data: [],
            graph: [],
            area: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            loadingGraph: false,
            loadingArea: false,
            isSync: false,
            params: {
                q: '',
                region: [],
                distributor: [],
                area: [],
                kabupaten: [],
                brand: [],
                toko: [],
                month: moment(new Date()).startOf('month').format('YYYY-MM'),
                page: 1,
                "per-page": 10,
            },
            selectedRows: [],
            selectedRowKeys: [],
            openClosePriode: true,
            openClosePriodeLastTime: '-',
            syncFinish: {
                loading: true,
                total: 0,
            },
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            fetchDataList()
        }

        const disabledDate = (current) => {
            return current.isAfter(new Date(), "month")
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                params.month = moment(state.params.month).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false

                    fetchSynchTotal()
                })
        }

        const fetchDataGraph = () => {
            state.loadingGraph = true
            apiClient
                .get(`/api/report/diagram-ca-v2`, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data.items) {
                        state.graph = data.items
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.loadingGraph = false
                })
        }

        const fetchDataArea = () => {
            state.loadingArea = true
            apiClient
                .get(`/api/report/toko-aktif-area-v2`, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data.items) {
                        state.area = data.items
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.loadingArea = false
                })
        }

        const fetchSynchOpenPeriode = () => {
            state.loadingArea = true
            apiClient
                .get(`/api/synchron-open/cek`, {
                    params: {
                        periode: queryParams().month,
                    },
                })
                .then(({ data }) => {
                    state.openClosePriode = data.openClosePriode
                })
        }

        const fetchSynchOpenPeriodeLastTime = () => {
            apiClient
                .get(`/api/report/transaksi-ca-v2`, {
                    params: {
                        month: queryParams().month,
                        _data: 'synchron',
                    },
                })
                .then(({ data }) => {
                    state.openClosePriodeLastTime = data.last_synchron
                })
        }

        const fetchSynchTotal = () => {
            state.syncFinish.loading = true
            apiClient
                .post(state.endpoint.sync_total, {
                    month: queryParams().month,
                })
                .then(({ data }) => {
                    state.syncFinish.total = ((state.meta.total / data.total * 100) || 0).toFixed(2)
                })
                .finally(() => {
                    state.syncFinish.loading = false
                })
        }

        const btnSynchron = () => {
            state.isSync = true
            apiClient
                .post(state.endpoint.sync, {
                    month: queryParams().month,
                })
                .then(({ data }) => {
                    message.success(data.message)
                })
                .finally(() => {
                    fetchSynchOpenPeriodeLastTime()
                    fetchSynchTotal()
                    state.isSync = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
            fetchDataGraph()
            fetchDataArea()
            fetchSynchOpenPeriode()
            fetchSynchOpenPeriodeLastTime()
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            // rowSelection,
            handleTableChange,
            onSelectChange,
            errorMessage,
            disabledDate,
            btnSynchron,
        }
    },
})
</script>